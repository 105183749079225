<template>
  <div>
    <div class="flex bg-gray-100 h-screen">
      <div class="w-full px-10 mt-12 sm:mt-20">
        <div
          class="text-gray-600 sm:text-3xl text-2xl max-w-lg mx-auto text-center"
        >
          <h1>Find Pediatric Therapists</h1>
        </div>
        <ul
          class="flex gap-x-5 gap-y-5 mt-10 mx-auto justify-center max-w-2xl flex-wrap"
        >
          <li>
            <NuxtLink
              class="flex flex-col gap-y-1.5 pt-1 items-center border border-gray-300 w-32 h-20 justify-center rounded-lg bg-white hover:bg-gray-200 active:bg-gray-200"
              href="/search?filter=services=PT"
              ><img src="/pt.png" class="w-8 h-8" alt="PT" />
              <span class="text-md text-gray-800">PT</span></NuxtLink
            >
          </li>
          <li>
            <NuxtLink
              class="flex flex-col gap-y-1.5 pt-1 items-center border border-gray-300 w-32 h-20 justify-center rounded-lg bg-white hover:bg-gray-200 active:bg-gray-200"
              href="/search?filter=services=OT"
              ><img src="/ot.png" class="w-8 h-8" alt="OT" />
              <span class="text-md text-gray-800">OT</span></NuxtLink
            >
          </li>
          <li>
            <NuxtLink
              class="flex flex-col gap-y-1.5 pt-1 items-center border border-gray-300 w-32 h-20 justify-center rounded-lg bg-white hover:bg-gray-200 active:bg-gray-200"
              href="/search?filter=services=Speech"
              ><img src="/slp.png" class="w-8 h-8" alt="Speech" />
              <span class="text-md text-gray-800">Speech</span></NuxtLink
            >
          </li>
          <li>
            <NuxtLink
              class="flex flex-col gap-y-1.5 pt-1 items-center border border-gray-300 w-32 h-20 justify-center rounded-lg bg-white hover:bg-gray-200 active:bg-gray-200"
              href="/search?filter=services=ABA"
              ><img src="/aba.png" class="w-8 h-8" alt="ABA" />
              <span class="text-md text-gray-800">ABA</span></NuxtLink
            >
          </li>
        </ul>
        <div class="flex mx-auto justify-center mt-5">
          <NuxtLink
            no-prefetch
            href="/search"
            class="text-sm text-gray-500 hover:text-gray-700 underline"
            >View all providers
          </NuxtLink>
        </div>
        <div class="relative my-5 max-w-sm mx-auto sm:mb-8 mt-10">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-gray-100 text-gray-500"
              >Or search for your provider</span
            >
          </div>
        </div>
        <form action="/search" method="GET" @submit.prevent="submit">
          <SearchBar v-model="query" class="hidden sm:block" />
          <MobileSearchBar />
          <div class="text-center mt-8">
            <button
              type="submit"
              class="rounded-md bg-red-400 px-3 py-2 text-sm text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-400"
              :disabled="!query"
            >
              Search
            </button>
          </div>
        </form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import { getAnalytics, logEvent } from 'firebase/analytics';
const query = ref('');
const submit = () => {
  const analytics = getAnalytics();
  logEvent(analytics, 'search', {
    query: query.value,
    from: 'home',
  });
  navigateTo('/search?' + new URLSearchParams({ q: query.value }).toString());
};

useHead({
  title: 'MyProviders - Find Pediatric Therapists',
  titleTemplate: '%s',
  meta: [
    {
      name: 'description',
      content:
        'Parents reviews and recommendations of best pediatric PT, OT, and speech therapists and more at MyProviders.',
    },
    {
      name: 'keywords',
      content:
        'physical therapy, occupational therapy, speech therapy, ABA, DMI therapy, intensive therapy',
    },
  ],
});

const location = useUserLocation();
</script>
